import { Badge, BadgeProps, Button, Menu, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createRecord, fetchCollection } from "redux/crud/action";
import { Link } from "react-router-dom";
import moment from "moment";

import { LOAN_STATUS, ROLE_NAME } from "constant";

import { useDispatch, useSelector } from "react-redux";

import "./notification.css";

const SKIP_NOTIFICATION_DAYS = 30;
const NotificationBadge = () => {
	const user: {
		role_name: string;
	} = JSON.parse(localStorage.getItem("tvsd.los.user"));

	const isUserCB = [ROLE_NAME.CREDIT_BUREAU, ROLE_NAME.CREDIT_BUREAU_ANALYST, ROLE_NAME.CREDIT_BUREAU_HEAD].includes(user?.role_name);

	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { auth, crudState } = useSelector((state: any) => state);
	const open = Boolean(anchorEl);

	const [unreadNotificationCount, setUnreadNotificationCount] =
		useState<number>(0);
	const [notificationList, setNotificationList] = useState<
		Array<{
			id: number;
			customer_id: number;
			customer_number: string;
			loan_application_id: string;
			deduplication_id?: string;
			loan_status: string;
			rec_date: string;
			action_type: string;
			message: string;
			is_read: boolean;
			source_user_id: number;
			source_user_first_name: string;
		}>
	>([]);

	useEffect(() => {
		if (auth) {
			dispatch(fetchCollection("notifications", "/notifications"));
		}
	}, [auth]);

	useEffect(() => {
		if (crudState?.notifications?.collections?.payload?.data?.notifications) {
			setNotificationList(
				crudState?.notifications?.collections?.payload?.data?.notifications,
			);
		}
		if (
			crudState?.notifications?.collections?.payload?.data &&
			Object.keys(crudState?.notifications?.collections?.payload?.data).includes(
				"unreadNotificationsCount",
			)
		) {
			setUnreadNotificationCount(
				crudState?.notifications?.collections?.payload?.data
					?.unreadNotificationsCount,
			);
		}
	}, [crudState.notifications]);

	useEffect(() => {
		if (
			crudState.notificationRead?.actionStatus?.create !== undefined &&
			crudState?.notificationRead?.actionStatus?.create?.pending === false &&
			crudState?.notificationRead?.actionStatus?.create?.success
		) {
			dispatch(fetchCollection("notifications", "/notifications"));
		}
	}, [crudState.notificationRead]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNotificationClick = (notification) => {
		if (!notification.is_read) {
			dispatch(
				createRecord("notificationRead", "/notification-read", {
					notificationId: notification.id,
				}),
			);
		}
	};

	const renderNotificationMessage = (event) => {
		const { message } = event;
		const messageArr = (message as string).split("*");

		let formattedMessage = message;
		switch (messageArr.length) {
			case 2:
				formattedMessage = `<b>${messageArr[0]}</b>${messageArr[1]}`;
				break;
			case 4:
				formattedMessage = `<b>${messageArr[0]}</b>${messageArr[1]}<b>${messageArr[2]}</b>${messageArr[3]}`;
				break;
			case 6:
				formattedMessage = `<b>${messageArr[0]}</b>${messageArr[1]}<b>${messageArr[2]}</b>${messageArr[3]}<b>${messageArr[4]}</b>${messageArr[5]}`;
				break;
			case 8:
				formattedMessage = `<b>${messageArr[0]}</b>${messageArr[1]}<b>${messageArr[2]}</b>${messageArr[3]}<b>${messageArr[4]}</b>${messageArr[5]}<b>${messageArr[6]}</b>${messageArr[7]}`;
				break;
			default:
				formattedMessage = message;
				break;
		}
		return formattedMessage;
	};

	return (
		<span className="d-flex align-items-center mr-2">
			<Button
				id="notification-button"
				aria-controls={open ? "notification-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{ width: "32px", height: "32px", minWidth: "20px" }}>
				<StyledBadge badgeContent={unreadNotificationCount} color="primary">
					<img
						src={`${process.env.PUBLIC_URL}/images/icon-notification.svg`}
						alt="icon-notification"
						style={{ width: "40px", height: "40px" }}
					/>
				</StyledBadge>
			</Button>
			<Menu
				id="notification-menu"
				disableScrollLock={true}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				MenuListProps={{
					"aria-labelledby": "notification-button",
				}}
				sx={{
					top: 18,
					paddingBottom: 0,
					".MuiMenu-paper": {
						borderRadius: 0,
					},
					".MuiList-root": {
						padding: 0,
					},
				}}>
				<div className="notification-badge-container">
					{(!crudState?.notifications?.collections?.payload?.data?.notifications ||
						crudState?.notifications?.collections?.payload?.data?.notifications
							?.length === 0) && (
							<div className="no-notification-container">No Notifications Yet</div>
						)}
					{notificationList.map((notification) => {
						if (
							moment().diff(moment(notification.rec_date), "days") >
							SKIP_NOTIFICATION_DAYS
						) {
							/* TODO : to be implemented when have instructed */
							// return null;
						}

						return (
							<div
								key={notification.id.toString()}
								onClick={() => handleNotificationClick(notification)}>
								<Link
									to={{
										pathname: [
											LOAN_STATUS.PENDING_SUBMISSION,
											LOAN_STATUS.CONDITIONAL_APPROVAL,
										].includes(notification.loan_status)
											? `/loans/new-application/${notification.loan_application_id}/${notification.customer_number}`
											: notification.loan_status === LOAN_STATUS.CREDIT_REVIEW &&
												notification?.deduplication_id &&
												isUserCB
												? `/dedupe/${notification?.deduplication_id}`
												: `/loan-details/${notification.loan_application_id}/${notification.customer_number}`,
										state: {
											id: notification.loan_application_id,
										},
									}}
									className={
										notification.is_read
											? "notification-button-read"
											: "notification-button-unread"
									}>
									<div className="initial-name-circle">
										{notification.source_user_first_name.slice(0, 1).toUpperCase()}
									</div>
									<div className="notification-message">
										<div
											className="notification-text"
											dangerouslySetInnerHTML={{
												__html: renderNotificationMessage(notification),
											}}
										/>
										<div className="notification-detail-time">
											{moment(notification.rec_date).fromNow()}
										</div>
									</div>
								</Link>
							</div>
						);
					})}
				</div>
			</Menu>
		</span>
	);
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: 8,
		top: 8,
		minWidth: "16px",
		width: "16px",
		height: "16px",
		fontSize: "10px",
		backgroundColor: "#E15C5C",
	},
}));

export default NotificationBadge;
